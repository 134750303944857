import {
  CssBaseline,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ThemeProvider, Theme, StyledEngineProvider } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { AccountBalance, AccountBox } from "@mui/icons-material";
import clsx from "clsx";
import React, { useContext, useState } from "react";
import OrderSelector from "../../../components/orderSelector/OrderSelector";
import StatBox from "../../../components/statBox/StatBox";
import Co2Stats from "../../../components/co2Stats/Co2Stats";
import NpsStats from "../../../components/npsStats/NpsStats";

import { IScreenProps } from "../../../types/app.types";
import { ILeadViewModel } from "../../../types/gameState.types";
import SalesOrderSearch from "../../../components/salesOrderSearch/SalesOrderSearch";
import appStyles from "../../../assets/appStyles";
import { AppContext } from "../../../context";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const SalesConsole: React.FC<IScreenProps> = ({ gameState, text, currencyParser }) => {
  document.title = `Sales | ${text.wrapper.gameName}`;

  const useStyles = makeStyles((theme) => ({
    padding: {
      maxWidth: "100%",
      height: "100%",
      padding: theme.spacing(3),
    },
    container: {
      paddingTop: theme.spacing(3.5),
      paddingBottom: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    column: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    tableCell: {
      padding: theme.spacing(1),
    },
    cheating: {
      paddingTop: "1px",
      height: "100%",
      padding: "10px 20px",
    },
    background: {
      //backgroundColor: theme.palette.secondary.main
    },
    searchCell: {
      padding: theme.spacing(0.1),
      textAlign: "center",
    },
    search: {
      minHeight: "40vh",
      maxHeight: "40vh",
    },
    retrieveOrder: {
      height: "calc(100% - 8px)",
    },
    fullHeight: {
      height: "100%",
    },
    wrapper: {
      // overflowY: 'auto',
      // minHeight: "75vh",
      // maxHeight: '75vh',
      // height: "40vh",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    tableContainer: {
      // minHeight: "75vh",
      // maxHeight: "75vh",
      // height: '40vh',
      overflow: "scroll",
    },
  }));

  const classes = useStyles();
  const appClasses = appStyles();
  const { state, dispatch } = useContext(AppContext);

  const leadSourceDetails = (lead: ILeadViewModel) => (
    <div className="leadSourceDetails">
      <Typography component="p" variant="subtitle2" color="textPrimary">
        {lead.TimePassed}
      </Typography>
      <Typography component="p" variant="subtitle1" color="textPrimary">
        {text.salesConsole.orderNumber} - {lead.OrderNumber}
      </Typography>
      <p>
        {" "}
        {lead.ContactForename} {lead.ContactSurname} at {lead.CompanyName}
      </p>
    </div>
  );

  const columnWidthTags = (
    <>
      <col width="30vw" />
      <col width="10vw" />
      <col width="10vw" />
      <col width="10vw" />
      <col width="10vw" />
      <col width="10vw" />
      <col width="10vw" />
    </>
  );

  const dataset = localStorage.getItem("dataset");

  return (
    <div className={classes.padding}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3} className={classes.background}>
            <Grid item xs={12} md={7} className={classes.fullHeight}>
              <Paper className={classes.retrieveOrder}>
                <SalesOrderSearch
                  labels={{
                    title: text.salesConsole.retrieveOrderDetails,
                    enterOrderNumber: text.salesConsole.enterOrderNumber,
                    lastThreeNumbers: text.salesConsole.lastThreeNumbers,
                    submit: text.salesConsole.search,
                    clear: text.salesConsole.clear,
                    feedback: text.salesConsole.feedback,
                  }}
                  role={"sales"}
                  gameState={gameState}
                  text={text}
                  currencyParser={currencyParser}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={5} className={classes.column}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <StatBox
                    value={currencyParser.format(gameState.TotalSales)}
                    title={text.salesConsole.totalAuthorisedOrders}
                    color="primary"
                  >
                    <AccountBalance />
                  </StatBox>
                </Grid>
                <Grid item xs={6}>
                  <StatBox
                    value={currencyParser.format(gameState.TotalCommission)}
                    title={text.salesConsole.totalCommissionPaid}
                    color="primary"
                  >
                    <AccountBox />
                  </StatBox>
                </Grid>
                <Grid item xs={12} style={{ width: state.isLargeScreen ? "auto" : "86vw" }}>
                  {dataset?.includes("gogreen") ? (
                    <Co2Stats
                      text={text}
                      itemSize={3}
                      currIndirectPerWeek={gameState.CO2IndirectPerWeek}
                      currDirectPerWeek={gameState.CO2DirectPerUnitPerWeek}
                      standardDirectPerUnit={gameState.Co2Reports[0].Co2SalesCostPerUnit}
                      standardOpsCostPerWeek={gameState.Co2Reports[0].Co2OperationalCostPerWeek}
                    />
                  ) : null}
                  {dataset?.includes("culture") ? <NpsStats text={text} itemSize={3} gameState={gameState} /> : null}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ width: state.isLargeScreen ? "auto" : "86vw" }}>
              <Paper className={classes.cheating}>
                <div className={appClasses.panelHeader}>
                  <h2 className={appClasses.panelTitle}>{text.salesConsole.repeatOrders}</h2>
                </div>
                <div className={classes.wrapper}>
                  <TableContainer className={classes.tableContainer}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell width="30vh" component="th" className={classes.tableCell}>
                            {text.salesConsole.repeatOrders}
                          </TableCell>
                          <TableCell width="10vh" component="th" className={classes.tableCell}>
                            {text.salesConsole.product}
                          </TableCell>
                          <TableCell width="10vh" component="th" className={classes.tableCell}>
                            {text.salesConsole.unit}
                          </TableCell>
                          <TableCell width="10vh" component="th" className={classes.tableCell}>
                            {text.salesConsole.orderValue}
                          </TableCell>
                          <TableCell width="10vh" component="th" className={classes.tableCell}>
                            {text.salesConsole.requiredWithin}
                          </TableCell>
                          <TableCell width="10vh" component="th" className={classes.tableCell}>
                            {text.salesConsole.lateDeliveryFine}
                          </TableCell>
                          <TableCell width="10vh" component="th" className={classes.tableCell}>
                            {text.salesConsole.purchaseOrder}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {state.gameState.AutoFac ? (
                          <TableRow>
                            <TableCell colSpan={5}>Repeat orders are not available in the demo.</TableCell>
                          </TableRow>
                        ) : (
                          gameState.AvailableSalesRepeatLeads.map((lead) => (
                            <TableRow className="salesTableRow" key={lead.OrderNumber}>
                              <TableCell component="td" className={classes.tableCell}>
                                {leadSourceDetails(lead)}
                              </TableCell>
                              <TableCell component="td" className={classes.tableCell}>
                                <Typography component="p" variant="subtitle1" color="textPrimary">
                                  {lead.ProductName}
                                </Typography>
                              </TableCell>
                              <TableCell component="td" className={classes.tableCell}>
                                <Typography component="p" variant="subtitle1" color="textPrimary">
                                  {lead.NumberOfUnits}
                                </Typography>
                              </TableCell>
                              <TableCell component="td" className={classes.tableCell}>
                                <Typography component="p" variant="subtitle1" color="textPrimary">
                                  {currencyParser.format(lead.TotalOrderValue)}
                                </Typography>
                              </TableCell>
                              <TableCell component="td" className={classes.tableCell}>
                                <Typography component="p" variant="subtitle1" color="textPrimary">
                                  {lead.RequiredWithinWeeks} Weeks
                                </Typography>
                              </TableCell>
                              <TableCell component="td" className={classes.tableCell}>
                                <Typography component="p" variant="subtitle1" color="textPrimary">
                                  {lead.CompensationForLateDelivery}
                                </Typography>
                              </TableCell>
                              <TableCell component="td" className={classes.tableCell}>
                                <Typography component="p" variant="subtitle1" color="textPrimary">
                                  {lead.PurchaseOrder}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default SalesConsole;
