const Reducer = (state: any, action: any) => {
  switch (action.type) {
    case "SET_ERROR":
      return {
        ...state,
        error: action.payload,
      };
    case "SET_GAMESTATE":
      return {
        ...state,
        gameState: action.payload,
      };
    case "SET_RUNNING_STATE":
      return {
        ...state,
        gameState: {
          ...state.gameState,
          RunningState: action.payload,
        },
      };
    case "SET_AVAILABLE_MARKETING_LEADS":
      return {
        ...state,
        gameState: {
          ...state.gameState,
          AvailableMarketingLeads: action.payload,
        },
      };
    case "SET_AVAILABLE_SALES_REPEAT_LEADS":
      return {
        ...state,
        gameState: {
          ...state.gameState,
          AvailableSalesRepeatLeads: action.payload,
        },
      };
    case "SET_AVAILABLE_SALES_SEARCHABLE_LEADS":
      return {
        ...state,
        gameState: {
          ...state.gameState,
          AvailableSalesSearchableLeads: action.payload,
        },
      };
    case "SET_NEWS_ITEMS":
      return {
        ...state,
        gameState: {
          ...state.gameState,
          NewsItems: action.payload,
        },
      };
    case "SET_CURRENT_PRODUCTION_ITEMS":
      return {
        ...state,
        gameState: {
          ...state.gameState,
          CurrentProductionItems: action.payload,
        },
      };
    case "SET_LAST_COMPLETED_ITEMS":
      return {
        ...state,
        gameState: {
          ...state.gameState,
          LastCompletedItems: action.payload,
        },
      };
    case "SET_BANK_VALUE_LIST":
      return {
        ...state,
        gameState: {
          ...state.gameState,
          BankValueList: action.payload,
        },
      };
    case "SET_BANK_CURRENT_NUMERIC":
      return {
        ...state,
        gameState: {
          ...state.gameState,
          BankCurrentNumeric: action.payload,
        },
      };
    case "SET_GAMESTATE_LAST_UPDATED":
      return {
        ...state,
        gameStateLastUpdated: action.payload,
      };
    case "SET_REFERENCEDATA":
      return {
        ...state,
        referenceData: action.payload,
      };
    case "SET_TEXT":
      return {
        ...state,
        text: action.payload,
      };
    case "SET_RKTCODE":
      return {
        ...state,
        rktCode: action.payload,
      };
    case "SET_ISREADY":
      return {
        ...state,
        isReady: action.payload,
      };
    case "SET_PARTICIPANTS":
      return {
        ...state,
        participants: action.payload,
      };
    case "SET_COUNTDOWN":
      return {
        ...state,
        countdown: action.payload,
      };
    case "SET_ISLARGESCREEN":
      return {
        ...state,
        isLargeScreen: action.payload,
      };
    case "SET_EULA_ACCEPTED":
      return {
        ...state,
        eulaAccepted: action.payload,
      };
    case "SET_SOCKET_CONNECTED":
      return {
        ...state,
        socketConnected: action.payload,
      };
    default:
      return state;
  }
};

export default Reducer;
