import {
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import ReplayIcon from "@mui/icons-material/Replay";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import StopIcon from "@mui/icons-material/Stop";
import CloseIcon from "@mui/icons-material/Close";
import React, { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import ConfirmDialog from "../../../components/confirmDialog/confirmDialog";
import WarningDialog from "../../../components/warningDialog/warningDialog";
import StatBox from "../../../components/statBox/StatBox";
import {
  clearMarketingCampaign,
  deployAdditionalProductionLine,
  endCurrentRound,
  pause,
  play,
  restart,
  resume,
  selectNextRound,
  selectPreviousRound,
} from "../../../modules/gameState/service";
import { IScreenProps } from "../../../types/app.types";
import {
  canEndRound,
  canPauseRound,
  canRestartRound,
  canResumeRound,
  canSelectNextRound,
  canSelectPreviousRound,
  canStartRound,
} from "../../../utils/determineAvailableActions";
import { CashPanel, CommissionPanel } from "./controlPanels";
import controlsTheme from "./controlPanels/controlsTheme";
import PlayerEvents from "./playerEvents/PlayerEvents";
import { CacConsultancy } from "./controlPanels/CacConsultancy";
import { AppContext } from "../../../context";
import { IPlayerEvent } from "../../../types/playerEvents.types";
import { recordFormSubmission } from "../../../modules/gameState/service";
import { Capabilities } from "./controlPanels/Capabilities";
import appStyles from "../../../assets/appStyles";
import { Md5 } from "ts-md5";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const useStyles = makeStyles((theme) => ({
  padding: {
    maxWidth: "100%",
    height: "100%",
    padding: theme.spacing(3),
  },
  cheating: {
    paddingTop: "1px",
    padding: "10px 40px",
    height: "100%",
    maxWidth: "86vw",
  },
  button: {
    margin: theme.spacing(1),
  },
  buttonGrid: {
    borderRight: "1px gray solid",
  },
}));

const GameControls: React.FC<IScreenProps> = ({ gameState, referenceData, text, currencyParser, numberParser }) => {
  const [product, setProduct] = useState("");
  const [useLoan, setUseLoan] = useState("false");
  const [confirmRestartLevelOpen, setConfirmRestartLevelOpen] = useState(false);
  const [confirmEndLevelOpen, setConfirmEndLevelOpen] = useState(false);
  const [confirmDeploySecondLineOpen, setConfirmDeploySecondLineOpen] = useState(false);
  const [confirmStartSimulationOpen, setConfirmStartSimulationOpen] = useState(false);
  const [prodLineFeedback, setProdLineFeedback] = useState(<Typography></Typography>);
  const [week, setWeek] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const { state } = useContext(AppContext);
  const [lineWarningOpen, setLineWarningOpen] = useState(false);

  document.title = `Controls | ${text.wrapper.gameName}`;

  const newDate = new Date();

  const form: IPlayerEvent = {
    id: localStorage.getItem("guid") + Date.now().toString(),
    GameId: localStorage.getItem("guid"),
    Console: "Facilitator",
    Round: gameState.Round.toString(),
    Week: gameState.Week.toString(),
    Submission: "",
    DateTime: newDate,
    Feedback: "",
  };

  const playAndNavigate = () => {
    play();
    recordFormSubmission({ ...form, Submission: "Play" });
  };

  const classes = useStyles();
  const appClasses = appStyles();

  const handleProductChange = (event: any) => {
    setProduct(event.target.value as string);
    recordFormSubmission({
      ...form,
      Submission: `Set Product to ${event.target.value}`,
    });
  };
  const handleUseLoanChange = (event: any) => {
    setUseLoan(event.target.value as string);
    recordFormSubmission({
      ...form,
      Submission: `Set Use loan to ${event.target.value}`,
    });
  };

  const runNextRound = () => {
    clearMarketingCampaign();
    selectNextRound();
    recordFormSubmission({ ...form, Submission: "Next Level" });
  };

  const runRestartRound = () => {
    restart();
    recordFormSubmission({ ...form, Submission: "Restart Level" });
  };

  const runPreviousRound = () => {
    clearMarketingCampaign();
    selectPreviousRound();
    recordFormSubmission({ ...form, Submission: "Previous Level" });
  };

  const handleDeploySecondProdLineClick = () => {
    if (!product || product === "") {
      setLineWarningOpen(true);
      return;
    }
    setConfirmDeploySecondLineOpen(true);
  };

  const handleDeploySecondProdLine = async () => {
    try {
      await deployAdditionalProductionLine(product, useLoan);
      setUseLoan("false");
      setProduct("A");
      setProdLineFeedback(<Typography style={{ color: "#3CBD89" }}>Production Line Deployed</Typography>);
      recordFormSubmission({
        ...form,
        Submission: "Deploy additional production line",
      });
    } catch (error) {
      setProdLineFeedback(<Typography color="error">Production Line Not Deployed</Typography>);
      if (error instanceof Error) {
        recordFormSubmission({
          ...form,
          Submission: "Deploy additional production line",
          Feedback: error.message,
        });
      }
    }
  };

  useEffect(() => {
    let isMounted = true;

    const tick = async () => {
      const lastUpdated = new Date(state.gameState.LastUpdated);

      const totalSecondsSinceLevelStart = state.gameState.Week * 60 + state.gameState.SecondsElapsedInWeek;
      const totalSecondsSinceLastUpdate =
        state.gameState.RunningState !== "Running"
          ? 0
          : Math.floor((new Date().getTime() - lastUpdated.getTime()) / 1000);
      const minutes = Math.floor((totalSecondsSinceLevelStart + totalSecondsSinceLastUpdate) / 60);
      const seconds = totalSecondsSinceLevelStart + totalSecondsSinceLastUpdate - minutes * 60;
      if (isMounted) {
        setWeek(minutes);
        setSeconds(seconds);
      }
    };

    const id = setInterval(tick, 1000);
    return () => {
      clearInterval(id);
      isMounted = false;
    };
  });

  const dataset = localStorage.getItem("dataset");
  const hashedDataset = dataset !== null ? Md5.hashStr(dataset) : "";
  if (localStorage.getItem("authFac") !== hashedDataset || gameState.AutoFac) {
    return <Navigate to="/game/main"></Navigate>;
  }

  return (
    <React.Fragment>
      <div className={classes.padding}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item md={3} xs={6}>
                <StatBox
                  title={text.facilitatorConsole.currentLevel}
                  value={gameState.Round != 0 ? "Level " + gameState.Round : "WALKAROUND"}
                >
                  <SportsEsportsIcon />
                </StatBox>
              </Grid>
              <Grid item md={3} xs={6}>
                <StatBox title={text.facilitatorConsole.currentWeek} value={week + " (" + seconds + ") secs"}>
                  <CalendarTodayIcon />
                </StatBox>
              </Grid>
              <Grid item md={3} xs={6}>
                <StatBox title={text.facilitatorConsole.restartsUsed} value={gameState.RestartCount + "/2"}>
                  <ReplayIcon />
                </StatBox>
              </Grid>
              <Grid item md={3} xs={6}>
                <StatBox title={"RUNNING STATE"} value={gameState.RunningState}>
                  <AccessTimeIcon />
                </StatBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.cheating}>
              <div className={appClasses.panelHeader}>
                <h2 className={appClasses.panelTitle}>{text.facilitatorConsole.levelSelectionControls}</h2>
              </div>
              <Button
                className={classes.button}
                onClick={runPreviousRound}
                variant="contained"
                disabled={!canSelectPreviousRound(gameState)}
                color="primary"
                name={text.facilitatorConsole.selectPreviousLevel.replace(" ", "")}
              >
                <SkipPreviousIcon />
                <Typography component="p" variant="subtitle2">
                  {text.facilitatorConsole.selectPreviousLevel}
                </Typography>
              </Button>
              <Button
                className={classes.button}
                onClick={gameState.IsStarted ? runNextRound : () => setConfirmStartSimulationOpen(true)}
                variant="contained"
                disabled={!canSelectNextRound(gameState)}
                color="primary"
                name={text.facilitatorConsole.selectNextLevel.replace(" ", "")}
              >
                <SkipNextIcon />
                <Typography component="p" variant="subtitle2">
                  {text.facilitatorConsole.selectNextLevel}
                </Typography>
              </Button>
              <ConfirmDialog
                title="Start Simulation"
                open={confirmStartSimulationOpen}
                setOpen={setConfirmStartSimulationOpen}
                onConfirm={() => runNextRound()}
              >
                Select Level 1 of the simulation. This will commit the use of the reserved token and cannot be undone.
              </ConfirmDialog>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={controlsTheme}>
                  <Button
                    className={classes.button}
                    variant="contained"
                    onClick={playAndNavigate}
                    disabled={!canStartRound(gameState) && !canResumeRound(gameState)}
                    color="primary"
                    name={text.facilitatorConsole.play.replace(" ", "")}
                  >
                    <PlayCircleFilledIcon />
                    <Typography component="p" variant="subtitle2">
                      {text.facilitatorConsole.play}
                    </Typography>
                  </Button>
                </ThemeProvider>
              </StyledEngineProvider>

              {/* <Button className={classes.button}
                                variant='outlined'
                                onClick={resumeAndNavigate}
                                disabled={!canResumeRound(gameState)}
                            >
                                <PlayCircleFilledWhiteOutlinedIcon color='primary' />
                                <Typography component='p' variant='subtitle2' >{text.facilitatorConsole.resume}</Typography>
                            </Button> */}
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={controlsTheme}>
                  <Button
                    className={classes.button}
                    variant="contained"
                    onClick={pause}
                    disabled={!canPauseRound(gameState)}
                    color="secondary"
                    name={text.facilitatorConsole.pause.replace(" ", "")}
                  >
                    <PauseCircleFilledIcon />
                    <Typography component="p" variant="subtitle2">
                      {text.facilitatorConsole.pause}
                    </Typography>
                  </Button>
                </ThemeProvider>
              </StyledEngineProvider>
              <Button
                className={classes.button}
                variant="contained"
                onClick={() => setConfirmEndLevelOpen(true)}
                disabled={!canEndRound(gameState)}
                color="primary"
                name={text.facilitatorConsole.endCurrentLevel.replace(" ", "")}
              >
                <StopIcon />
                <Typography component="p" variant="subtitle2">
                  {text.facilitatorConsole.endCurrentLevel}
                </Typography>
              </Button>
              <ConfirmDialog
                title="End current level"
                open={confirmEndLevelOpen}
                setOpen={setConfirmEndLevelOpen}
                onConfirm={() => endCurrentRound()}
              >
                Warning: this action cannot be undone. Do you want to continue?
              </ConfirmDialog>
              <Button
                className={classes.button}
                variant="contained"
                onClick={() => setConfirmRestartLevelOpen(true)}
                disabled={!canRestartRound(gameState)}
                color="primary"
                name={text.facilitatorConsole.restartCurrentLevel.replace(" ", "")}
              >
                <ReplayIcon />
                <Typography component="p" variant="subtitle2">
                  {text.facilitatorConsole.restartCurrentLevel}
                </Typography>
              </Button>
              <ConfirmDialog
                title="Restart current level"
                open={confirmRestartLevelOpen}
                setOpen={setConfirmRestartLevelOpen}
                onConfirm={() => runRestartRound()}
              >
                Warning: this action cannot be undone. All investment applied since the last level will need to be
                re-applied! Do you want to continue?
              </ConfirmDialog>
            </Paper>
          </Grid>

          <Grid item md={6} xs={12}>
            <Paper className={classes.cheating}>
              <div className={appClasses.panelHeader}>
                <h2 className={appClasses.panelTitle}>{text.facilitatorConsole.cashOptions}</h2>
              </div>
              <CashPanel gameState={gameState} text={text.facilitatorConsole} />
            </Paper>
          </Grid>
          <Grid item md={6} xs={12}>
            <Paper className={classes.cheating}>
              <div className={appClasses.panelHeader}>
                <h2 className={appClasses.panelTitle}>{text.facilitatorConsole.commission}</h2>
              </div>
              <CommissionPanel gameState={gameState} text={text.facilitatorConsole} />
            </Paper>
          </Grid>
          <Grid item md={6} xs={12}>
            <Paper className={classes.cheating}>
              <div className={appClasses.panelHeader}>
                <h2 className={appClasses.panelTitle}>{text.facilitatorConsole.aplDeployment}</h2>
              </div>
              <Grid container>
                <Grid item md={4} xs={12}>
                  <InputLabel id="lblService">Select {text.productionConsole.product}</InputLabel>
                  <Select
                    defaultValue=""
                    onChange={handleProductChange}
                    aria-label={text.productionConsole.product}
                    name={text.productionConsole.product.replace(" ", "")}
                    labelId="lblService"
                    required
                  >
                    {referenceData.Products.map((product) => (
                      <MenuItem key={product.Id} value={product.Name}>
                        {product.Name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item md={4} xs={12}>
                  <InputLabel id="lblUseALoan">Use a loan?</InputLabel>
                  <Select
                    defaultValue="false"
                    onChange={handleUseLoanChange}
                    aria-label="Use a loan?"
                    name="UseALoan"
                    labelId="lblUseALoan"
                  >
                    <MenuItem value={"true"}>Yes</MenuItem>
                    <MenuItem value={"false"}>No</MenuItem>
                  </Select>
                </Grid>
                <Grid item md={4} xs={12}>
                  <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={controlsTheme}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleDeploySecondProdLineClick()}
                        name="Deploy"
                      >
                        Deploy
                      </Button>
                    </ThemeProvider>
                  </StyledEngineProvider>
                  <div aria-live="polite">{prodLineFeedback}</div>
                  <ConfirmDialog
                    title={text.facilitatorConsole.aplDeployment}
                    open={confirmDeploySecondLineOpen}
                    setOpen={setConfirmDeploySecondLineOpen}
                    onConfirm={handleDeploySecondProdLine}
                  >
                    Warning: this action cannot be undone. Do you want to continue?
                  </ConfirmDialog>
                  <WarningDialog
                    open={lineWarningOpen}
                    onClose={() => setLineWarningOpen(false)}
                    title="Warning"
                    message="Please select a product before deploying."
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item md={6} xs={12}>
            <Paper className={classes.cheating}>
              <div className={appClasses.panelHeader}>
                <h2 className={appClasses.panelTitle}>{text.facilitatorConsole.cacConsultancyFee}</h2>
              </div>
              <CacConsultancy text={text.facilitatorConsole} gameState={gameState} />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.cheating}>
              <div className={appClasses.panelHeader}>
                <h2 className={appClasses.panelTitle}>{"Player Behaviours"}</h2>
              </div>
              <Capabilities referenceData={referenceData} gameState={gameState} />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.cheating}>
              <div className={appClasses.panelHeader}>
                <h2 className={appClasses.panelTitle}>{"Player Interactions"}</h2>
              </div>
              <PlayerEvents
                gameState={gameState}
                referenceData={referenceData}
                text={text}
                currencyParser={currencyParser}
                numberParser={numberParser}
              />
            </Paper>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};
export default GameControls;
