import React, { createContext, useEffect, useReducer, useRef, useState } from "react";
import Reducer from "./reducer";
import { IGameState, IReferenceData, IParticipants, ICountdown } from "./types/gameState.types";
import { ITextSet } from "./types/text.types";
import { getSocketData } from "./modules/socket/service";

type InitialStateType = {
  gameState: IGameState;
  gameStateLastUpdated: Date;
  referenceData: IReferenceData;
  text: ITextSet;
  isLargeScreen: boolean;
  rktCode: string;
  isReady: boolean;
  participants: IParticipants;
  countdown: ICountdown;
  eulaAccepted: boolean;
  socketConnected: boolean;
};

const initialState = {
  gameState: {} as IGameState,
  gameStateLastUpdated: new Date(),
  referenceData: {} as IReferenceData,
  text: {} as ITextSet,
  isLargeScreen: false,
  rktCode: "" as string,
  isReady: false as boolean,
  participants: { connected: 0, readyUsers: [], ready: 0 } as IParticipants,
  countdown: {} as ICountdown,
  eulaAccepted: false,
  socketConnected: false,
};

const AppContext = createContext<{
  state: InitialStateType;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

interface Props {
  children: React.ReactNode;
}

const AppProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  const webSocketRef = useRef<WebSocket | null>(null);
  const [hasConnected, setHasConnected] = useState(false);

  const rktCodeRef = useRef();
  rktCodeRef.current = state.rktCode;
  const ackIdRef = useRef(0);
  const joinGroupAckIdRef = useRef(0);

  // Functions

  const handleMessage = (message: any) => {
    const container = JSON.parse(message);
    const ws = webSocketRef.current;

    switch (container.type) {
      case "message":
        const msg = container.data;

        switch (msg.NotificationType) {
          case "RunningState":
            dispatch({
              type: "SET_RUNNING_STATE",
              payload: msg.Message,
            });
            break;
          case "AvailableMarketingLeads":
            dispatch({
              type: "SET_AVAILABLE_MARKETING_LEADS",
              payload: JSON.parse(msg.Message),
            });
            break;
          case "AvailableSalesRepeatLeads":
            dispatch({
              type: "SET_AVAILABLE_SALES_REPEAT_LEADS",
              payload: JSON.parse(msg.Message),
            });
            break;
          case "AvailableSalesSearchableLeads":
            dispatch({
              type: "SET_AVAILABLE_SALES_SEARCHABLE_LEADS",
              payload: JSON.parse(msg.Message),
            });
            break;
          case "NewsItems":
            dispatch({
              type: "SET_NEWS_ITEMS",
              payload: JSON.parse(msg.Message),
            });
            break;
          case "CurrentProductionItems":
            dispatch({
              type: "SET_CURRENT_PRODUCTION_ITEMS",
              payload: JSON.parse(msg.Message),
            });
            break;
          case "LastCompletedItems":
            dispatch({
              type: "SET_LAST_COMPLETED_ITEMS",
              payload: JSON.parse(msg.Message),
            });
            break;
          case "BankValueList":
            dispatch({
              type: "SET_BANK_VALUE_LIST",
              payload: JSON.parse(msg.Message),
            });
            break;
          case "BankCurrentNumeric":
            dispatch({
              type: "SET_BANK_CURRENT_NUMERIC",
              payload: JSON.parse(msg.Message),
            });
            break;
          case "MvLastUpdated":
            dispatch({
              type: "SET_GAMESTATE_LAST_UPDATED",
              payload: new Date(msg.MvLastUpdated),
            });
            break;
          case "Participants":
            dispatch({
              type: "SET_PARTICIPANTS",
              payload: {
                connected: msg.Connected,
                ready: msg.Ready,
                readyUsers: msg.ReadyUsers,
              },
            });
            break;
          case "Countdown":
            dispatch({
              type: "SET_COUNTDOWN",
              payload: { isRunning: msg.IsRunning, seconds: msg.Countdown },
            });
            break;
          default:
            break;
        }
        break;
      case "ack":
        if (container.ackId === joinGroupAckIdRef.current && ws !== null) {
          ws.send(
            JSON.stringify({
              type: "event",
              event: "joinedGroup",
              ackId: ++ackIdRef.current,
              dataType: "json",
              data: {
                group: rktCodeRef.current,
              },
            })
          );
          joinGroupAckIdRef.current = 0;
        }
        break;
      default:
        break;
    }
  };

  const fetchSocketData = async () => {
    const data = await getSocketData();

    if (data !== null) {
      connect(data.url);
    } else {
      setTimeout(fetchSocketData, 1000);
    }
  };

  let retryCount = 0;

  const connect = (url: string) => {
    if (webSocketRef.current && webSocketRef.current.readyState === WebSocket.OPEN) {
      return;
    }
    webSocketRef.current = new WebSocket(url, "json.webpubsub.azure.v1");
    const ws = webSocketRef.current;

    ws.onopen = function () {
      setHasConnected(true);
      dispatch({ type: "SET_SOCKET_CONNECTED", payload: true });
      retryCount = 0;
    };

    ws.onmessage = function (e) {
      handleMessage(e.data);
    };

    ws.onclose = function (e) {
      setHasConnected(false);
      dispatch({ type: "SET_SOCKET_CONNECTED", payload: false });

      // Attempt to reconnect after 1 second
      if (retryCount < 5) {
        // Max of 5 retries
        retryCount += 1;
        setTimeout(() => connect(url), 1000);
      } else {
        // If connection still fails after retries, fetch socket data again after 5 seconds
        setTimeout(fetchSocketData, 5000);
      }
    };

    ws.onerror = function (err) {
      ws.close();
    };
  };

  // Effects

  useEffect(() => {
    fetchSocketData();

    return () => {
      if (webSocketRef.current) {
        webSocketRef.current.close();
      }
    };
  }, []);

  useEffect(() => {
    if (!hasConnected || !state.rktCode) {
      return;
    }
    const ws = webSocketRef.current;
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(
        JSON.stringify({
          type: "joinGroup",
          group: "all",
          ackId: ++ackIdRef.current,
        })
      );
      ws.send(
        JSON.stringify({
          type: "joinGroup",
          group: rktCodeRef.current,
          ackId: ++ackIdRef.current,
        })
      );
      joinGroupAckIdRef.current = ackIdRef.current;
    }
  }, [state.rktCode, hasConnected]);

  useEffect(() => {
    // 4) When "I'm Ready" is ticked, send IsReady event
    const ws = webSocketRef.current;
    const sendIsReady = () => {
      if (hasConnected) {
        ws?.send(
          JSON.stringify({
            type: "event",
            event: "isReady",
            ackId: ++ackIdRef.current,
            dataType: "json",
            data: state.isReady,
          })
        );
      } else {
        setTimeout(function () {
          sendIsReady();
        }, 1000);
      }
    };

    if (hasConnected) {
      sendIsReady();
    }
  }, [hasConnected, state.isReady]);

  return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>;
};

export { AppContext, AppProvider };
